import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

const loginMutation = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    loginYoung(email: $email, password: $password) {
      token
      user {
        id
      }
    }
  }
`

const loginMutationOptions = {
  props: ({ mutate }) => ({
    loginYoung: async ({ email, password }) => {
      return mutate({
        variables: {
          email,
          password,
        },

        update: (cache, { data }) => {
          const { loginYoung } = data
          const { user } = loginYoung
          cache.writeData({
            data: {
              authStatus: {
                __typename: 'authStatus',
                logged: true,
                user: user.id,
              },
            },
          })
        },
      })
    },
  }),
}

export default graphql(loginMutation, loginMutationOptions)
