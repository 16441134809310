import React from 'react'
import { compose } from 'recompose'

import AuthRoute from 'auth-route'
import SendMessageChat from 'widgets/send-message-chat'
import Header from 'widgets/header-name/team-member.js'
import FeedChat from 'widgets/feed-chat-young'
import { Box } from 'design-system'

import sendMessage from 'graphql/mutations/chat/sendMessage'
import getYoungMessages from 'graphql/queries/young/getYoungMessages'

const Messages = compose(getYoungMessages)(FeedChat)
const SendMessageInput = compose(sendMessage)(SendMessageChat)

const UserChatConversation = ({ data, history, match }) => {
  const { authStatus } = data
  const { user } = authStatus
  const { id } = match.params

  return (
    <Box
      width="100%"
      flex={1}
      height="100vh"
      p={0}
      mx="auto"
      bg="white"
      maxWidth={5}
    >
      {/* header */}
      <Header id={id} onClickBack={() => history.goBack()} />
      <Messages young={user} user={id} />
      <SendMessageInput recipient={id} sender={user} />
    </Box>
  )
}

export default compose(AuthRoute)(UserChatConversation)
