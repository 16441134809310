import React from 'react'
import 'styled-components/macro'

import { makeStyles } from '@material-ui/core/styles'
import { pink } from '@material-ui/core/colors'

import Avatar from '@material-ui/core/Avatar'

import Tag from 'components/tag'
import { Box, Heading, Text } from 'design-system'
import { formatDate } from 'utils/date'
import { postCategories } from '../../enums'

const useStyles = makeStyles(theme => ({
  avatar: {
    width: 35,
    height: 35,
  },
  initialsAvatar: {
    fontSize: 12,
    width: 35,
    height: 35,
    color: '#fff',
    backgroundColor: pink[300],
  },
}))

const TimelineCard = ({
  headerTitle,
  avatarSrc,
  file,
  senderName,
  senderRole,
  text,
  fileName,
  tagName,
  tagFileColor,
  tagFileTextColor,
  tagColor,
  tagTextColor,
  ...otherProps
}) => {
  const classes = useStyles()
  return (
    <Box width="90%" maxWidth={5} mx="auto" {...otherProps}>
      <Box display="flex" my={15}>
        <Heading
          color="off-white"
          fontWeight="normal"
          lineHeight="title"
          fontSize={2}
          flex={1}
          pl={3}
        >
          {formatDate(headerTitle)}
        </Heading>
      </Box>

      {/* Content Block */}
      <Box
        bg="white"
        borderRadius={2}
        display="flex"
        minHeight={2}
        mt={2}
        p={3}
      >
        <Box display="flex" width="100%" flexDirection="column">
          {/* Text */}
          <Box display="flex" width="100%" flexDirection="column" mb={2}>
            <Text color="black" lineHeight="normal" fontSize={3} margin={0}>
              {text}
            </Text>
          </Box>

          {/* Sender */}
          <Box
            pt={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              minWidth="150px"
            >
              {/* Image */}
              {avatarSrc ? (
                <Avatar
                  alt={senderName}
                  src={avatarSrc}
                  className={classes.avatar}
                />
              ) : (
                <Avatar className={classes.initialsAvatar}>
                  {senderName.charAt(0).toUpperCase()}
                  {senderName
                    .split(' ')[1]
                    .charAt(0)
                    .toUpperCase()}
                </Avatar>
              )}
              {/* Name and role */}
              <Box
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                ml={2}
                mt={1}
              >
                <Text
                  as="span"
                  fontSize={0}
                  color="black"
                  lineHeight="18px"
                  fontWeight="600"
                >
                  {senderName}
                </Text>
                <Text
                  as="span"
                  color="title-gray"
                  fontSize={0}
                  lineHeight="16px"
                >
                  {senderRole}
                </Text>
              </Box>
            </Box>

            {/* Tag */}
            <Tag bgColor={tagColor}>
              <Text
                lineHeight="normal"
                textAlign="center"
                fontSize={0}
                margin={0}
                color={tagTextColor}
              >
                {postCategories[tagName]}
              </Text>
            </Tag>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TimelineCard
