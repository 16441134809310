import React from 'react'
import { Box, theme, Text } from 'design-system'

import { LoginIcon } from 'components/icons'
import { ButtonLogin } from './style'

const RoleChooser = ({ history }) => {
  const handleRedirect = role => {
    localStorage.setItem('userRole', role)
    history.push(`/${role}-login`)
  }

  return (
    <Box
      width="100vw"
      minHeight="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      bg="white"
    >
      <Box my={2}>
        <LoginIcon fill={theme.colors.black} />
      </Box>
      <Text>Login as</Text>
      <Box display="flex" flexDirection="row" width={300}>
        <ButtonLogin onClick={() => handleRedirect('user')}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Young Person
          </Box>
        </ButtonLogin>
        <ButtonLogin onClick={() => handleRedirect('team')}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            Staff
          </Box>
        </ButtonLogin>
      </Box>
    </Box>
  )
}

export default RoleChooser
