import React from 'react'
import { compose } from 'react-apollo'
import AuthRoute from 'auth-route'
import Menu from 'components/menu'
import YoungProfile from 'pages/young/profile/young-profile'
import { Box } from 'design-system'

const YoungProfileIndex = ({ history, match, user }) => {
  return (
    <Box width="100%" flex={1} height="100vh" p={0} mx="auto" maxWidth={5}>
      <YoungProfile history={history} match={match} user={user} isUser />
      <Menu value={1} isUser />
    </Box>
  )
}

export default compose(AuthRoute)(YoungProfileIndex)
