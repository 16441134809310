import React, { useState, useEffect, createRef } from 'react'
import { compose } from 'recompose'

import { Box } from 'design-system'
import RightBalloon from 'components/chat-balloon/right-balloon'
import LeftBalloon from 'components/chat-balloon/left-balloon'

import markNotificationsAsReadMutation from 'graphql/mutations/notification/markNotificationsAsRead'

const FeedChat = ({ young, user, data, markNotificationsAsRead }) => {
  const [subscription, setSubscription] = useState(null)
  const { messages, subscribeToNewMessages } = data
  const messagesRef = createRef()

  const filteredMessages = messages.filter(
    m => m.sender === young || m.sender === user
  )

  function subscribe() {
    setSubscription(subscribeToNewMessages)
  }

  function unsubscribe() {
    if (subscription) {
      subscription()
    }
  }

  function scrollToBottom() {
    const scrollHeight = messagesRef.current.scrollHeight
    const height = messagesRef.current.clientHeight
    const maxScrollTop = scrollHeight - height
    messagesRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0
  }

  // run every render
  useEffect(() => {
    scrollToBottom()
  })

  // run on mount/unmount only once
  useEffect(() => {
    scrollToBottom()
    subscribe()
    return () => {
      unsubscribe()
    }
  }, []) /* eslint-disable-line */

  // mark notifications as read
  useEffect(
    () => {
      markNotificationsAsRead({ user })
    },
    [young, messages, markNotificationsAsRead]
  )

  return (
    <Box
      p={3}
      mb={5}
      ref={messagesRef}
      width="100%"
      height="calc(100vh - 120px)"
      overflow="auto"
      css={{
        overscrollBehavior: 'contain contain',
        scrollBehavior: 'smooth',
        overflowY: 'scroll',
        overflowX: 'hidden',
      }}
    >
      {filteredMessages.map(chat => {
        const isSender = chat.sender === young
        const { file } = chat
        return !isSender ? (
          <LeftBalloon
            file={file}
            text={chat.message}
            key={`container-balloon-conversation-${chat.id}`}
          />
        ) : (
          <RightBalloon
            file={file}
            text={chat.message}
            key={`container-balloon-conversation-${chat.id}`}
          />
        )
      })}
    </Box>
  )
}

export default compose(markNotificationsAsReadMutation)(FeedChat)
