import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

import { Box, theme } from 'design-system'
import { ChatIconAttach } from 'components/icons'
import SendRoundedIcon from '@material-ui/icons/SendRounded'
import SendButton from 'components/send-button'

import newNotificationMutation from 'graphql/mutations/notification/newNotification'

const StyledCircularProgress = withStyles({
  root: {
    color: theme.colors.blue,
  },
})(CircularProgress)

const SendMessageChat = ({
  recipient,
  sender,
  sendMessage,
  newNotification,
}) => {
  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [attachment, setAttachment] = useState(null)

  useEffect(
    () => {
      // mount
      setAttachment(null)
      setValue('')
    },
    [recipient]
  )

  async function handleInputSubmit() {
    const inputValue = value.trim()
    if (inputValue.length === 0) return
    setLoading(true)
    await sendMessage({
      sender,
      recipient,
      message: inputValue,
      file: attachment,
    })
    newNotification({
      sender,
      recipient,
    })
    setLoading(false)
    setValue('')
    setAttachment(null)
  }

  function handleOnChange(e) {
    setValue(e.target.value)
  }

  function handleKeyPress(e) {
    if (loading) return
    if (e.keyCode === 13) {
      handleInputSubmit()
    }
  }

  function handleFileChange(e) {
    const files = e.target.files
    const file = files[0]
    setAttachment(file)
  }

  return (
    <Box
      height="50px"
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      maxWidth={5}
      px={1}
      py={3}
      position="fixed"
      bottom={0}
      bg="#fff"
      zIndex={999}
    >
      <UploadBox
        width={40}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ChatIconAttach
          fill={attachment ? theme.colors['blue'] : theme.colors['icon-attach']}
        />
        <form>
          <input
            type="file"
            disabled={loading}
            onChange={handleFileChange}
            onClick={e => {
              e.target.value = null
            }}
          />
        </form>
      </UploadBox>
      <Box width="100%" height={40}>
        <Input
          placeholder="Type a message ..."
          value={value}
          type="text"
          name="message"
          autoComplete="off"
          autoCorrect="off"
          onChange={handleOnChange}
          onKeyDown={handleKeyPress}
          disabled={loading}
          autoFocus
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width={44}
        height={44}
      >
        {loading && <StyledCircularProgress size={30} color="primary" />}
        {!loading && (
          <SendButton color="#2A8BF2" onClick={handleInputSubmit}>
            <SendRoundedIcon color="secondary" style={{ fontSize: 20 }} />
          </SendButton>
        )}
      </Box>
    </Box>
  )
}

export default compose(newNotificationMutation)(SendMessageChat)

const Input = styled('input')(({ theme }) => ({
  width: '100%',
  margin: 0,
  color: '#000',
  paddingLeft: 5,
  paddingRight: 5,
  height: '100%',
  border: 0,
  outline: 'none',
  fontFamily: 'manrope',
  fontSize: theme.fontSizes[2],
  maxHeight: 50,
  '::placeholder': {
    color: '#D3D3D3',
    opacity: 1,
    fontSize: theme.fontSizes[2],
  },
}))

const UploadBox = styled(Box)({
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  'input[type=file]': {
    cursor: 'pointer',
    fontSize: '100px',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
  },
})
