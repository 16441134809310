import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import { blue } from '@material-ui/core/colors'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import { youngStatuses } from 'enums'

import { callSidebarPlaceholder } from '../../utils/placeholder-factory'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 16,
    fontFamily: 'Manrope',
    color: 'black',
  },
  blueAvatar: {
    fontSize: 14,
    width: 50,
    height: 50,
    marginRight: 15,
    color: '#fff',
    backgroundColor: blue[300],
  },
}))

const CasesList = ({ data, yourCases, isUser }) => {
  const { cases, otherCases, loading } = data
  const list = yourCases ? cases : otherCases
  const classes = useStyles()

  if (loading) {
    return callSidebarPlaceholder()
  }

  return (
    list.length !== 0 &&
    list.map(item => {
      const { young } = item
      const avatarSrc = young.avatar ? young.avatar.url : young.avatarUrl
      return (
        <StyledNavLink
          key={`people-${yourCases}-${young.id}`}
          to={{
            pathname: isUser
              ? `/user-timeline/${young.id}`
              : `/timeline/${young.id}`,
            aboutProps: { ...young },
          }}
        >
          <ListItem button alignItems="center">
            {avatarSrc ? (
              <ListItemAvatar>
                <Avatar
                  className={classes.blueAvatar}
                  alt={young.name}
                  src={young.avatarUrl}
                />
              </ListItemAvatar>
            ) : (
              <ListItemAvatar>
                <Avatar className={classes.blueAvatar}>
                  {young.name.charAt(0).toUpperCase()}
                  {young.name
                    .split(' ')[1]
                    .charAt(0)
                    .toUpperCase()}
                </Avatar>
              </ListItemAvatar>
            )}
            <ListItemText
              className={classes.text}
              primary={young.name}
              secondary={youngStatuses[young.status]}
            />
          </ListItem>
          {/* blah */}
        </StyledNavLink>
      )
    })
  )
}

export default CasesList

const StyledNavLink = styled(NavLink)({
  width: '100%',
  paddingLeft: 0,
  alignItems: 'center',
  display: 'flex',
})
