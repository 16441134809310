import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Box } from 'design-system'
import ConversationItem from './conversation-item'
import { callSidebarPlaceholder } from 'utils/placeholder-factory'

const Conversations = ({ data, user, yourCases, isUser }) => {
  const { cases, otherCases, loading } = data
  const list = yourCases ? cases : otherCases

  if (loading) {
    return callSidebarPlaceholder()
  }

  return (
    <Box>
      {list.length !== 0 &&
        list.map(item => {
          const { young } = item
          const avatarSrc = young.avatar ? young.avatar.url : young.avatarUrl
          return (
            <StyledNavLink
              key={`people-${yourCases}-${young.id}`}
              to={{
                pathname: isUser
                  ? `/user-chat-conversation/${young.id}`
                  : `/conversation/${young.id}`,
                aboutProps: { ...young },
              }}
            >
              <ConversationItem
                user={user}
                young={young.id}
                avatarSrc={avatarSrc}
                name={young.name}
              />
            </StyledNavLink>
          )
        })}
    </Box>
  )
}

export default Conversations

const StyledNavLink = styled(NavLink)({
  width: '100%',
  paddingLeft: 0,
  alignItems: 'center',
  display: 'flex',
})
