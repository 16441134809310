import React from 'react'
import styled from 'styled-components'

const BlackButton = ({ title, onClick, disabled, ...otherProps }) => (
  <Button mx={2} onClick={onClick} disabled={disabled}>
    {title}
  </Button>
)

const Button = styled.button(({ disabled, theme }) => ({
  backgroundColor: '#1E1E1E',
  fontWeight: theme.fontWeights[1],
  fontSize: theme.fontSizes[0],
  fontFamily: theme.fonts.title,
  color: theme.colors.white,
  padding: '3px 5px',
  outline: 'none',
  borderRadius: 3,
  height: '45px',
  width: '100%',
  border: 0,
  margin: 5,
  transition: 'opacity 0.3s ease',
  opacity: disabled ? 0.5 : 1,
  cursor: disabled ? 'default' : 'pointer',
}))

export default BlackButton
