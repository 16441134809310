import React from 'react'
import { Box } from 'design-system'

import BorderButton from 'components/buttons/bordered'
import BlackButton from 'components/buttons/normal'

const SideButtons = ({
  onClickBordered,
  onClickBlack,
  titleBordered,
  titleBlack,
}) => (
  <Box
    height={60}
    width="95%"
    display="flex"
    alignItems="center"
    mx="auto"
    my={2}
    maxWidth={5}
    justifyContent="space-between"
  >
    <BorderButton title={titleBordered} onClick={onClickBordered} />
    <BlackButton title={titleBlack} onClick={onClickBlack} />
  </Box>
)

export default SideButtons
