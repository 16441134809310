import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, split } from 'apollo-link'
import { ApolloProvider } from 'react-apollo'
import { withClientState } from 'apollo-link-state'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { CachePersistor } from 'apollo-cache-persist'
import { WebSocketLink } from 'apollo-link-ws'
import { getMainDefinition } from 'apollo-utilities'
import { createUploadLink } from 'apollo-upload-client'

import ChatConversation from 'pages/chat/conversation'
import YoungTimeline from 'pages/young/timeline'
import YoungProfile from 'pages/young/profile'
import ChatList from 'pages/chat/list'
import Settings from 'pages/settings'
import People from 'pages/people'
import Login from 'pages/login'
import UserLogin from 'pages/user-stack/login'

import { theme } from 'design-system'
import UserWrapper from 'user-wrapper'
import TeamWrapper from 'team-wrapper'
import UserProfile from 'pages/user-stack/profile'
import RoleChooser from 'pages/role-chooser'
import UserTimeline from 'pages/user-stack/timeline'
import UserContactList from 'pages/user-stack/contact-list'
import UserChatList from 'pages/user-stack/chat/list'
import UserChatConversation from 'pages/user-stack/chat/conversation'
import UserSettings from 'pages/user-stack/settings'
import ScrollToTop from 'hooks/scroll-to-top'

// Material
import { ThemeProvider as Mui } from '@material-ui/styles'
import { MuiTheme } from './theme/muiTheme'

const isDev = process.env.NODE_ENV === 'development'

const httpLink = createUploadLink({
  uri: isDev
    ? 'http://localhost:4000'
    : 'https://muse-demo-graphql.herokuapp.com',
})

const cache = new InMemoryCache()

const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
  debug: true,
})

const stateLink = withClientState({
  cache,
  resolvers: {},
  defaults: {
    authStatus: {
      __typename: 'authStatus',
      logged: false,
      user: null,
      role: null,
    },
  },
})

const authMiddleware = setContext(async (req, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) console.error('ApolloClient networkError: ', networkError)
  if (graphQLErrors) {
    // eslint-disable-next-line
    graphQLErrors.map(({ message, locations, path }) => {
      console.error('ApolloClient graphQLErrors: ', message)
      if (message.indexOf('Authorised') > -1) {
        // every 401/unauthorized error will be caught here and update the global local state
        localStorage.clear()

        cache.writeData({
          data: {
            authStatus: {
              __typename: 'authStatus',
              logged: false,
            },
          },
        })
      }
    })
  }
})

const wsLink = new WebSocketLink({
  uri: isDev
    ? 'ws://localhost:4000'
    : 'wss://muse-demo-graphql.herokuapp.com',
  options: {
    reconnect: true,
    connectionParams: {
      authToken: localStorage.getItem('token'),
    },
  },
})

const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  ApolloLink.from([authMiddleware, errorLink, stateLink, httpLink])
)

const client = new ApolloClient({
  link,
  cache,
  resolvers: {},
})

client.onResetStore(stateLink.writeDefaults)

class App extends Component {
  state = {
    restored: false,
    networkConnection: true,
  }

  handleConnection = e => {
    if (e.type === 'online') {
      this.setState({ networkConnection: true })
    }
    if (e.type === 'offline') {
      this.setState({ networkConnection: false })
    }
  }

  componentDidMount = async () => {
    window.addEventListener('offline', this.handleConnection)
    window.addEventListener('online', this.handleConnection)
    await persistor.restore()
    this.setState({ restored: true })
  }

  componentWillUnmount = () => {
    window.removeEventListener('offline', this.handleConnection)
    window.removeEventListener('online', this.handleConnection)
  }

  addDefaultSrc = ev => {
    ev.target.src = './images/pensive-face.png'
  }

  render() {
    const { networkConnection, restored } = this.state

    return restored ? (
      <ApolloProvider client={client}>
        <Mui theme={MuiTheme}>
          <ThemeProvider theme={theme}>
            <HashRouter>
              <ScrollToTop>
                {networkConnection ? (
                  <Switch>
                    <Route exact path="/" component={RoleChooser} />

                    {/* Team stack */}
                    <TeamWrapper exact path="/team-login" component={Login} />
                    <TeamWrapper
                      exact
                      path="/timeline/:id"
                      component={YoungTimeline}
                    />
                    <TeamWrapper
                      exact
                      path="/profile/:id"
                      component={YoungProfile}
                    />
                    <TeamWrapper exact path="/chat" component={ChatList} />
                    <TeamWrapper
                      exact
                      path="/conversation/:id"
                      component={ChatConversation}
                    />
                    <TeamWrapper exact path="/people" component={People} />
                    <TeamWrapper path="/settings" component={Settings} />

                    {/* User stack */}
                    <UserWrapper path="/user-login" component={UserLogin} />
                    <UserWrapper
                      path="/user-contact-list"
                      component={UserContactList}
                    />
                    <UserWrapper
                      path="/user-timeline"
                      component={UserTimeline}
                    />
                    <UserWrapper path="/user-profile" component={UserProfile} />
                    <UserWrapper
                      path="/user-chat-list"
                      component={UserChatList}
                    />
                    <UserWrapper
                      path="/user-chat-conversation/:id"
                      component={UserChatConversation}
                    />
                    <UserWrapper
                      path="/user-settings"
                      component={UserSettings}
                    />
                  </Switch>
                ) : (
                  <div>No Connection</div>
                )}
              </ScrollToTop>
            </HashRouter>
          </ThemeProvider>
        </Mui>
      </ApolloProvider>
    ) : (
      <div>Loading</div>
    )
  }
}

export default App
