import React from 'react'
import { compose } from 'react-apollo'
import AuthRoute from 'auth-route'
import { Box } from 'design-system'

import { getCases, getOtherCases } from 'graphql/queries/cases/getCases'
import Title from 'components/title'
import CasesList from 'components/cases-list'
import Menu from 'components/menu'

const YourCases = compose(getCases)(CasesList)
const OtherCases = compose(getOtherCases)(CasesList)

const People = ({ history }) => {
  return (
    <Box
      minHeight="100vh"
      mx="auto"
      maxWidth={5}
      pb={60}
      overflow="scroll"
      bg="white"
    >
      <Title title="Your cases" mt={0} pt={4} mb={2} />
      <YourCases yourCases />
      <Title title="Other" />
      <OtherCases />
      <Menu value={0} />
    </Box>
  )
}
export default compose(AuthRoute)(People)
