import React from 'react'
import { Redirect, Route } from 'react-router-dom'

export const TeamWrapper = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        const role = localStorage.getItem('userRole')
        const user = localStorage.getItem('user')
        const param = props.location.state && props.location.state.role
        if (role === 'team' || param === 'team') {
          return <Component user={user} {...props} />
        }
        return (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }}
    />
  )
}

export default TeamWrapper
