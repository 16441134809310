import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'

import { makeStyles } from '@material-ui/core/styles'
import { red } from '@material-ui/core/colors'

import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { Box, Text } from 'design-system'
import ConversationItemText from './conversation-item-text'

import getUnreadNotifications from 'graphql/queries/notification/getUnreadNotifications'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: 16,
    fontFamily: 'Manrope',
    color: 'black',
  },
  avatar: {
    width: 50,
    height: 50,
    marginRight: 15,
  },
  redAvatar: {
    fontSize: 14,
    width: 50,
    height: 50,
    marginRight: 15,
    color: '#fff',
    backgroundColor: red[300],
  },
}))

const ConversationItem = ({ data, user, young, avatarSrc, name }) => {
  const classes = useStyles()
  const [subscription, setSubscription] = useState(null)
  const { notifications, subscribeToNewNotifications } = data
  const allNotifications = notifications.filter(n => n.sender === young)

  function subscribe() {
    setSubscription(subscribeToNewNotifications)
  }

  function unsubscribe() {
    if (subscription) {
      subscription()
    }
  }

  useEffect(() => {
    subscribe()
    return () => {
      unsubscribe()
    }
  }, []) /* eslint-disable-line */

  return (
    <ListItem alignItems="center" className={classes.list}>
      {avatarSrc ? (
        <ListItemAvatar>
          <Avatar alt={name} src={avatarSrc} className={classes.avatar} />
        </ListItemAvatar>
      ) : (
        <ListItemAvatar>
          <Avatar className={classes.redAvatar}>
            {name.charAt(0).toUpperCase()}
            {name
              .split(' ')[1]
              .charAt(0)
              .toUpperCase()}
          </Avatar>
        </ListItemAvatar>
      )}
      <ConversationItemText
        className={classes.text}
        name={name}
        young={young}
        user={user}
      />
      {allNotifications.length > 0 && (
        <Box
          ml="auto"
          display="flex"
          alignItems="center"
          justifyContent="center"
          width={26}
          height={26}
          bg="red"
          borderRadius={5}
        >
          <Text fontSize={0} color="white" lineHeight={0}>
            {allNotifications.length}
          </Text>
        </Box>
      )}
    </ListItem>
  )
}

export default compose(getUnreadNotifications)(ConversationItem)
