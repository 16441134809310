import React from 'react'
import { compose } from 'react-apollo'
import AuthRoute from 'auth-route'
import Menu from 'components/menu'
import YoungProfile from './young-profile'
import { Box } from 'design-system'

const YoungProfileIndex = ({ history, match }) => {
  return (
    <Box width="100%" flex={1} height="100vh" p={0} mx="auto" maxWidth={5}>
      <YoungProfile history={history} match={match} user={match.params.id} />
      <Menu value={0} />
    </Box>
  )
}

export default compose(AuthRoute)(YoungProfileIndex)
