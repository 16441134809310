import React from 'react'
import Linkify from 'react-linkify'
import 'styled-components/macro'
import { Box, theme, Text } from 'design-system'

const RightBalloon = ({ text, file }) => {
  const image = file && file.mimetype.includes('image')
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="flex-end"
      justifyContent="flex-end"
      flexDirection="column"
      maxWidth={5}
      mx="auto"
      my={2}
    >
      {text && (
        <Box bg="chat" borderRadius={2} display="flex" maxWidth={286} px={3}>
          <Linkify>
            <Text
              color="white"
              fontSize={theme.fontSizes[2]}
              fontFamily={theme.fonts.sans}
              css={{
                wordBreak: 'break-word',
              }}
            >
              {text}
            </Text>
          </Linkify>
        </Box>
      )}
      {file && image && (
        <a href={file.url} download>
          <Box
            py={2}
            borderRadius={2}
            width="100%"
            maxWidth={286}
            height="auto"
            overflow="hidden"
          >
            <img
              width="100%"
              src={file.url}
              alt={text}
              style={{ border: 0, outline: 'none', borderRadius: 2 }}
            />
          </Box>
        </a>
      )}
    </Box>
  )
}

export default RightBalloon
