import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export const getYoungCaseQuery = gql`
  query GetYoungCase($young: ID!) {
    getYoungCase(young: $young) {
      owners {
        id
        name
        avatarUrl
        avatar {
          url
        }
      }
    }
  }
`

export const getYoungCaseOptions = {
  props: ({ data: { error, loading, getYoungCase } }) => ({
    data: {
      error,
      loading,
      owners: getYoungCase && getYoungCase[0].owners,
    },
  }),
  options: ({ young }) => ({
    variables: { young },
    fetchPolicy: 'cache-and-network',
  }),
}

const getYoungCase = graphql(getYoungCaseQuery, getYoungCaseOptions)
export default getYoungCase
