import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export const getYoungQuery = gql`
  query YoungQuery($young: ID!) {
    young(id: $young) {
      name
      email
      avatarUrl
      location
      address
      addressType
      dob
      status
      notes
      interests
      avatar {
        url
      }
    }
  }
`

export const getYoungOptions = {
  options: ({ young }) => ({
    variables: { young },
    fetchPolicy: 'cache-and-network',
  }),
}

export default graphql(getYoungQuery, getYoungOptions)
