import React, { Fragment } from 'react'
import { Text, Box, Button } from 'design-system'
import { Query, graphql } from 'react-apollo'
import { makeStyles } from '@material-ui/core/styles'
import { deepPurple } from '@material-ui/core/colors'
import Avatar from '@material-ui/core/Avatar'
import * as serviceWorker from 'service-worker'

import { getCurrentUserQuery } from 'graphql/queries/user/getCurrentUser'

import { version } from 'enums'

const avatarSize = 65

const useStyles = makeStyles(theme => ({
  avatar: {
    width: avatarSize,
    height: avatarSize,
  },
  initialsAvatar: {
    fontSize: 16,
    width: avatarSize,
    height: avatarSize,
    color: '#fff',
    backgroundColor: deepPurple[500],
  },
}))

async function logout(client, history) {
  try {
    serviceWorker.unregister()
    await client.resetStore()
    localStorage.clear()
    setTimeout(() => history.push('/'), 100)
  } catch (e) {
    console.log('ERROR ON LOGOUT', e)
  }
}

function Settings({ history, client }) {
  const classes = useStyles()
  return (
    <Box position="relative" pt={3} px={3}>
      <Query query={getCurrentUserQuery}>
        {({ data, client }) => {
          return (
            <Fragment>
              {data && data.me && (
                <Box display="flex" flex={1} flexDirection="column">
                  {data.me.avatar || data.me.avatarUr ? (
                    <Avatar
                      alt={data.me.name}
                      src={
                        data.me.avatar ? data.me.avatar.url : data.me.avatarUrl
                      }
                      className={classes.avatar}
                    />
                  ) : (
                    <Avatar className={classes.initialsAvatar}>
                      {data.me.name.charAt(0).toUpperCase()}
                      {data.me.name
                        .split(' ')[1]
                        .charAt(0)
                        .toUpperCase()}
                    </Avatar>
                  )}
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    pt={3}
                  >
                    <Text m={0} fontSize={3}>
                      <strong>{data.me.name}</strong>
                    </Text>
                    <Text m={0} fontSize={2} color="gray">
                      {data.me.email}
                    </Text>
                  </Box>

                  <Text mt={3} mb={0} fontSize={3}>
                    <strong>Version</strong>
                  </Text>
                  <Text mt={0} mb={3} fontSize={2} color="gray">
                    v{version}
                  </Text>
                  <Button
                    flex={1}
                    bg="#fff"
                    border="solid 2px #ddd !important"
                    color="#111"
                    onClick={() => logout(client, history)}
                  >
                    Sign Out
                  </Button>
                </Box>
              )}
            </Fragment>
          )
        }}
      </Query>
    </Box>
  )
}

export default graphql(getCurrentUserQuery)(Settings)
