import React from 'react'

import { Box } from 'design-system'

const Tag = ({ bgColor, children, px, ...otherProps }) => (
  <Box
    justifyContent="center"
    wordBreak="break-word"
    px={px ? px : 3}
    alignItems="center"
    fontFamily="sans"
    borderRadius={1}
    display="flex"
    fontWeight={1}
    minWidth={50}
    bg={bgColor}
    fontSize={1}
    height={35}
    border={0}
    {...otherProps}
  >
    {children}
  </Box>
)

export default Tag
