import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export const getYoungPostsQuery = gql`
  query YoungPostsQuery($young: ID!, $category: String) {
    youngPosts(id: $young, category: $category) {
      id
      text
      createdAt
      category
      createdBy {
        name
        avatarUrl
        role
        avatar {
          url
        }
      }
      file {
        id
        filename
        url
      }
    }
  }
`

export const getYoungPostsOptions = {
  props: ({ data: { error, loading, networkStatus, youngPosts } }) => ({
    data: {
      error,
      loading,
      networkStatus,
      youngPosts,
      hasPosts: youngPosts && youngPosts.length > 0,
    },
  }),
  options: ({ young, category }) => ({
    variables: {
      young,
      category,
    },
    fetchPolicy: 'cache-and-network',
    /* pollInterval: 1000 demo */
  }),
}

export default graphql(getYoungPostsQuery, getYoungPostsOptions)
