import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import FilterListIcon from '@material-ui/icons/FilterList'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import { Box } from 'design-system'
import HeaderName from 'widgets/header-name'
import CreateLog from 'widgets/create-log'
import FilterLog from 'widgets/filter-log'
import Posts from './posts'

import { postCategories } from 'enums'

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1),
  },
}))

const YoungTimeline = ({ history, match, user, isUser }) => {
  const id = isUser ? user : match.params.id
  const [isCreateOpen, setCreateOpen] = useState(false)
  const [isFilterOpen, setFilterOpen] = useState(false)
  const [filterItem, setFilterItem] = useState('')
  const [postsLoading, setPostLoading] = useState(false)
  const [hasPosts, setHasPosts] = useState(false)
  const [showClearFilter, setShowClearFilter] = useState(false)
  const classes = useStyles()

  useEffect(
    () => {
      if (filterItem.length > 0 && !postsLoading) {
        setShowClearFilter(true)
      }
    },
    [filterItem]
  )

  function handleOnPostLoading({ hasPosts, loading }) {
    setPostLoading(loading)
    setHasPosts(hasPosts)
  }

  function handleFilterOpen() {
    if (postsLoading) return
    if (!hasPosts && !postsLoading) return
    setFilterOpen(!isFilterOpen)
  }

  function handleCreateOpen() {
    if (postsLoading) return
    // if (!hasPosts && !postsLoading) return
    setCreateOpen(!isCreateOpen)
  }

  function handleOnClickFilter(value) {
    setFilterItem(value)
    setFilterOpen(false)
  }

  function handleResetFilterClick() {
    setShowClearFilter(false)
    setFilterItem('')
  }

  return (
    <Fragment>
      {!isCreateOpen && !isFilterOpen && (
        <Fragment>
          <HeaderName
            young={id}
            onClick={() => {
              if (!isUser) history.push(`/profile/${id}`)
            }}
            onClickBack={() => history.goBack()}
            iconOne={<FilterListIcon />}
            iconTwo={<AddCircleIcon />}
            hasPosts={hasPosts}
            postsLoading={postsLoading}
            onClickIconOne={handleFilterOpen}
            onClickIconTwo={handleCreateOpen}
            isUser={isUser}
          />
          {showClearFilter && (
            <Box
              py={2}
              width="90%"
              display="flex"
              mx="auto"
              alignItems="center"
            >
              <Box ml={2}>
                <Chip
                  label={filterItem}
                  variant="outlined"
                  onClick={handleResetFilterClick}
                  onDelete={handleResetFilterClick}
                  className={classes.chip}
                />
              </Box>
            </Box>
          )}
          <Posts
            young={id}
            category={filterItem.toString().toUpperCase()}
            onPostLoading={handleOnPostLoading}
          />
        </Fragment>
      )}
      {isCreateOpen && (
        <CreateLog
          young={id}
          onClickCancel={() => setCreateOpen(!isCreateOpen)}
          onClickCreate={() => setCreateOpen(false)}
        />
      )}
      {isFilterOpen && (
        <FilterLog
          onClickFilter={handleOnClickFilter}
          onClickCancel={() => setFilterOpen(!isFilterOpen)}
          filterOptions={Object.keys(postCategories).map((k, index) => ({
            id: index,
            title: postCategories[k],
            value: k,
          }))}
        />
      )}
    </Fragment>
  )
}

export default YoungTimeline
