import React from 'react'
import { Box, Text, theme } from 'design-system'

const Title = ({ title, ...otherProps }) => (
  <Box
    height={30}
    display="flex"
    alignItems="center"
    position="relative"
    pb={3}
    pt={4}
    ml={3}
    {...otherProps}
  >
    <Text
      fontFamily={theme.fonts.title}
      color="black"
      fontSize={theme.fontSizes[4]}
      lineHeight="33px"
      m={0}
    >
      {title}
    </Text>
  </Box>
)

export default Title
