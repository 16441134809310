import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { getUnreadNotificationsQuery } from 'graphql/queries/notification/getUnreadNotifications'

const markNotificationsAsReadQuery = gql`
  mutation markNotificationsAsRead($user: ID!) {
    markNotificationsAsRead(user: $user)
  }
`

const markNotificationsAsReadOptions = {
  props: ({ mutate }) => ({
    markNotificationsAsRead: async ({ user }) => {
      return mutate({
        variables: {
          user,
        },
        // Update cache
        refetchQueries: [
          {
            query: getUnreadNotificationsQuery,
            variables: {
              user,
            },
          },
        ],
      })
    },
  }),
}

export default graphql(
  markNotificationsAsReadQuery,
  markNotificationsAsReadOptions
)
