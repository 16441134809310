import React, { useEffect } from 'react'
import styled from 'styled-components'

import { makeStyles } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'

import TimelineCard from 'components/cards/timeline'
import { Box, Text } from 'design-system'
import { compose } from 'recompose'

import getYoungPosts from 'graphql/queries/young/getYoungPosts'

import { roles } from 'enums'

const useStyles = makeStyles({
  root: {
    height: 2,
    flexGrow: 1,
  },
})

const NotFound = () => (
  <Box p={3}>
    <Text>No posts found!</Text>
  </Box>
)

function TimelinePosts({
  young,
  data,
  category,
  onClearButtonClick,
  onPostLoading,
}) {
  const { hasPosts, youngPosts, loading, networkStatus } = data
  const classes = useStyles()

  useEffect(
    () => {
      onPostLoading({ hasPosts, loading })
    },
    [loading]
  )

  if ((loading && networkStatus === 1) || young === ':id') {
    return (
      <ProgressBox display="flex" position="relative">
        <div className={classes.root}>
          <LinearProgress thickness={2} />
        </div>
      </ProgressBox>
    )
  }

  if (!hasPosts && loading === false) {
    return <NotFound />
  }

  return (
    <Box width="100%" minHeight="100vh" pb={60} position="relative" zIndex={2}>
      {youngPosts.map(item => {
        const avatarSrc = item.createdBy.avatar
          ? item.createdBy.avatar.url
          : item.createdBy.avatarUrl
        return (
          <TimelineCard
            key={`card-by-${item.createdBy.name}-${item.createdAt}-${item.id}`}
            avatarSrc={avatarSrc}
            senderName={item.createdBy.name}
            senderRole={roles[item.createdBy.role]}
            text={item.text}
            headerTitle={item.createdAt}
            tagColor={`${item.category.toLowerCase()}`}
            tagTextColor={`${item.category.toLowerCase()}-text`}
            tagName={item.category}
            file={item.file}
          />
        )
      })}
    </Box>
  )
}

export default compose(getYoungPosts)(TimelinePosts)

const ProgressBox = styled(Box)({
  'div.MuiLinearProgress-root': {
    height: 2,
  },
})
