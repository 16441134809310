import React from 'react'
import ReactPlaceholder from 'react-placeholder'
import { RoundShape, RectShape } from 'react-placeholder/lib/placeholders'

import 'react-placeholder/lib/reactPlaceholder.css'
import { Box, theme } from 'design-system'

export const RoundedPlaceholder = ({
  width,
  height,
  ready = false,
  color,
  margin,
  marginRight,
}) => (
  <RoundShape
    delay={1000}
    ready={ready}
    rows={1}
    color={color ? color : theme.colors['background']}
    style={{ width, height, margin, marginRight, flex: `0 0 ${width}px` }}
  />
)

export const SquarePlaceholder = ({
  width,
  height,
  ready = false,
  color,
  margin,
}) => (
  <RectShape
    delay={1000}
    ready={ready}
    rows={1}
    color={color ? color : theme.colors['background']}
    style={{ width, height, margin, borderRadius: '2px' }}
  />
)

export const ButtonPlaceholder = ({ style, color }) => (
  <RectShape rows={1} color={color ? color : '#E0E0E0'} style={style} />
)

export const DefaultPlaceholder = ({
  type,
  style,
  ready = false,
  color,
  firstLaunch,
}) => (
  <ReactPlaceholder
    firstLaunchOnly={firstLaunch ? firstLaunch : false}
    type={type ? type : 'textRow'}
    ready={false}
    color={color ? color : '#E0E0E0'}
    style={style}
  />
)

export const HeaderPlaceholder = () => (
  <Box
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="flex-start"
  >
    <RoundedPlaceholder
      color={theme.colors['title-gray']}
      width={60}
      height={60}
      margin={10}
    />
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      flex="1"
    >
      <SquarePlaceholder
        color={theme.colors['title-gray']}
        width={200}
        margin={3}
        height={15}
      />
      <SquarePlaceholder
        color={theme.colors['title-gray']}
        width={150}
        margin={3}
        height={15}
      />
    </Box>
    <Box
      display="flex"
      alignItems="flex-end"
      justifyContent="flex-end"
      right="0"
      mr="30px"
    >
      <ButtonPlaceholder
        style={{
          width: '150px',
          height: '50px',
          marginLeft: 5,
          marginRight: 5,
          background: 'transparent',
          border: '2px solid #E0E0E0',
          borderRadius: '5px',
        }}
      />
      <ButtonPlaceholder
        style={{
          width: '200px',
          height: '50px',
          marginLeft: 5,
          marginRight: 5,
          background: 'transparent',
          border: '2px solid #E0E0E0',
          borderRadius: '5px',
        }}
      />
    </Box>
  </Box>
)
export const GenericPlaceholder = () => (
  <Box
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="flex-start"
  >
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      flex="1"
    >
      <SquarePlaceholder
        color={theme.colors['title-gray']}
        width={200}
        margin={3}
        height={15}
      />
      <SquarePlaceholder
        color={theme.colors['title-gray']}
        width={150}
        margin={3}
        height={15}
      />
    </Box>
  </Box>
)

export const PostPlaceholder = () => (
  <Box
    justifyContent="center"
    flexDirection="column"
    alignItems="center"
    display="flex"
    width="100%"
    maxWidth={5}
    mx="auto"
    mb="30px"
    mt="20px"
  >
    <Box
      justifyContent="flex-start"
      alignItems="center"
      display="flex"
      width="100%"
      p={3}
    >
      <SquarePlaceholder
        color={theme.colors['title-gray']}
        width={200}
        margin={3}
        height={15}
      />
    </Box>
    <Box
      justifyContent="flex-start"
      border={`2px solid ${theme.colors['title-gray']}`}
      alignItems="flex-start"
      flexDirection="column"
      borderRadius="3px"
      display="flex"
      height="100px"
      width="100%"
      p={3}
    >
      <SquarePlaceholder
        color={theme.colors['tag-light']}
        width={150}
        margin={3}
        height={15}
      />
      <SquarePlaceholder
        color={theme.colors['tag-light']}
        width={200}
        margin={3}
        height={15}
      />
    </Box>
  </Box>
)
