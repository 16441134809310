import React from 'react'
import { Text, Box, theme } from 'design-system'

import SideButtons from 'widgets/side-buttons'
import Filter from 'components/filter'
import RadioGroup from '@material-ui/core/RadioGroup'

const FilterLog = ({
  onClickFilter,
  onClickCancel,
  onFilter,
  filterOptions,
  filterItems,
}) => {
  const [value, setValue] = React.useState('')

  function handleChange(event) {
    setValue(event.target.value)
  }

  return (
    <Box
      position="relative"
      zIndex={10}
      height="100vh"
      bg="white"
      flexDirection="column"
      width="100%"
      display="flex"
      alignItems="center"
      mx="auto"
      maxWidth={5}
      justifyContent="space-between"
    >
      <Box
        flex={1}
        width="100%"
        display="flex"
        alignItems="flex-start"
        mx="auto"
        overflow="scroll"
        p={3}
        maxWidth={5}
        flexDirection="column"
      >
        <Text
          fontFamily={theme.fonts.title}
          color="black"
          fontSize={theme.fontSizes[4]}
          lineHeight="33px"
          m={0}
          mb={10}
        >
          Filter
        </Text>

        {/* Filter List */}
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
        >
          <Filter
            // onFilter={onFilter}
            options={filterOptions}
            filterItems={filterItems}
          />
        </RadioGroup>
      </Box>
      <SideButtons
        titleBlack="Filter"
        titleBordered="Cancel"
        onClickBlack={() => onClickFilter(value)}
        onClickBordered={onClickCancel}
      />
    </Box>
  )
}

export default FilterLog
