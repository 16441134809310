import React, { Fragment } from 'react'
import { Box, Text } from 'design-system'
import { compose } from 'recompose'

import ProfileCard from 'components/cards/profile'
import getYoung from 'graphql/queries/young/getYoung'
import { formatDateBirthday } from 'utils/date'
import { youngInterests } from 'enums'

const ProfileCards = ({ young, data }) => {
  return (
    <Fragment>
      {data && data.young && (
        <Box width="100%" minHeight="100vh" overflow="auto">
          <ProfileCard key="info" title="Basic Information">
            <Text
              color="title-blue"
              fontSize={1}
              lineHeight="normal"
              letterSpacing={1}
              m={0}
            >
              Address
            </Text>
            <Text color="black" lineHeight="normal" fontSize={2} margin={0}>
              {data.young.address}
            </Text>

            <Text
              color="title-blue"
              fontSize={1}
              lineHeight="normal"
              letterSpacing={1}
              mt={2}
              mb={0}
            >
              DOB
            </Text>
            <Text color="black" lineHeight="normal" fontSize={2} margin={0}>
              {formatDateBirthday(new Date(data.young.dob).toISOString())}
            </Text>
          </ProfileCard>
          <ProfileCard key="dream" title="Dream">
            <Text color="black" lineHeight="normal" fontSize={2} margin={0}>
              {data.young.notes}
            </Text>
          </ProfileCard>
          <ProfileCard key="interests" title="Interests">
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {data.young.interests.map(key => (
                <Box
                  m={1}
                  px={2}
                  py={1}
                  display="inline-block"
                  bg="title-blue"
                  borderRadius="3px"
                >
                  <Text color="white" fontSize={0} m={0}>
                    {youngInterests[key]}
                  </Text>
                </Box>
              ))}
            </Box>
          </ProfileCard>
        </Box>
      )}
    </Fragment>
  )
}

export default compose(getYoung)(ProfileCards)
