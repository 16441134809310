import React from 'react'
import { Box } from 'design-system'

import {
  PostPlaceholder,
  RoundedPlaceholder,
  SquarePlaceholder,
  GenericPlaceholder,
} from '../components/content-placeholders'

export const callPostsPlaceholder = () =>
  ['', '', ''].map((i, index) => (
    <PostPlaceholder
      key={`posts-placeholder-${index}`}
      width={60}
      height={60}
      margin={5}
    />
  ))

export const callSidebarPlaceholder = () =>
  ['', '', '', '', '', ''].map((i, index) => (
    <Box
      key={`sidebar-placeholder-${index}`}
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      py={14}
      px={3}
      width="100%"
    >
      <RoundedPlaceholder ready={false} width={50} height={50} />
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height={37}
        ml={3}
        justifyContent="space-between"
      >
        <SquarePlaceholder ready={false} width={150} height={15} />
        <SquarePlaceholder ready={false} width={115} height={15} />
      </Box>
    </Box>
  ))

export const moreGenericPlaceholder = () =>
  ['', '', ''].map((i, index) => (
    <Box
      key={`sidebar-placeholder-${index}`}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      height="55px"
      width="95%"
      px="6px"
    >
      <GenericPlaceholder />
      <GenericPlaceholder />
    </Box>
  ))

export const callSearchPlaceholder = () => (
  <SquarePlaceholder ready={false} width="100%" height={35} />
)

export const callSmTitlePlaceholder = () => (
  <SquarePlaceholder ready={false} width={120} height={20} />
)

export const callGenericPlaceholder = () => <GenericPlaceholder />

export const headerPlaceholder = () => (
  <Box
    display="flex"
    justifyContent="flex-start"
    alignItems="center"
    py={16}
    px={3}
    width="100%"
  >
    <RoundedPlaceholder color="#fff" ready={false} width={40} height={40} />
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height={37}
      ml={3}
      justifyContent="space-between"
    >
      <SquarePlaceholder color="#fff" ready={false} width={150} height={15} />
      <SquarePlaceholder color="#fff" ready={false} width={115} height={15} />
    </Box>
  </Box>
)
