import React, { Fragment } from 'react'

import HeaderName from 'widgets/header-name'
import ProfileCards from './profile-cards'

const YoungProfile = ({ history, match, user, isUser }) => {
  return (
    <Fragment>
      <HeaderName
        young={isUser ? user : match.params.id}
        onClick={() => {}}
        onClickBack={() => history.goBack()}
        isUser={isUser}
      />
      <ProfileCards young={user} />
    </Fragment>
  )
}

export default YoungProfile
