import {
  space,
  width,
  height,
  color,
  fontSize,
  fontFamily,
  fontWeight,
  lineHeight,
  letterSpacing,
  borders,
  borderColor,
  borderRadius,
  textAlign,
  display,
  maxWidth,
  minWidth,
  boxShadow,
  minHeight,
  backgroundImage,
  backgroundSize,
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  flex,
  order,
  position,
  zIndex,
  top,
  right,
  bottom,
  left,
  overflow,
  opacity,
  border,
} from 'styled-system'

import styled from 'styled-components'
import { theme } from './theme'

export const Box = styled('div')(
  {},
  space,
  width,
  height,
  color,
  fontSize,
  borders,
  borderColor,
  borderRadius,
  boxShadow,
  textAlign,
  display,
  maxWidth,
  minWidth,
  minHeight,
  backgroundImage,
  backgroundSize,
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  flex,
  order,
  position,
  zIndex,
  top,
  right,
  bottom,
  left,
  overflow,
  opacity
)

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...height.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
  ...borders.propTypes,
  ...borderColor.propTypes,
  ...borderRadius.propTypes,
  ...boxShadow.propTypes,
  ...textAlign.propTypes,
  ...display.propTypes,
  ...maxWidth.propTypes,
  ...minWidth.propTypes,
  ...backgroundImage.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...flexWrap.propTypes,
  ...flexDirection.propTypes,
  ...flex.propTypes,
  ...order.propTypes,
  ...position.propTypes,
  ...zIndex.propTypes,
  ...top.propTypes,
  ...right.propTypes,
  ...bottom.propTypes,
  ...left.propTypes,
}

export const Text = styled('p')(
  {},
  space,
  color,
  fontSize,
  border,
  // typography
  fontFamily,
  textAlign,
  lineHeight,
  fontWeight,
  letterSpacing
)

Text.propTypes = {
  ...space.propTypes,
  ...color.propTypes,
  ...fontSize.propTypes,
  ...fontFamily.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...fontWeight.propTypes,
  ...letterSpacing.propTypes,
}

Text.defaultProps = {
  fontSize: 2,
  color: 'black',
  fontFamily: 'sans',
}

export const Heading = styled(Text)({})
Heading.defaultProps = {
  as: 'h1',
  m: 0,
  fontSize: 4,
  color: 'black',
  fontWeight: 2,
  fontFamily: 'title',
  lineHeight: 'title',
}

export const Button = styled(Text)({
  appearance: 'none',
  border: 'none',
  borderRadius: '4px',
})

Button.defaultProps = {
  display: 'block',
  m: 0,
  py: 3,
  px: 5,
  as: 'button',
  fontWeight: 1,
  fontSize: 1,
  fontFamily: 'sans',
  color: 'white',
  bg: theme.colors.button,
}
