import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import { getYoungPostsQuery } from 'graphql/queries/young/getYoungPosts'

const createYoungPostMutationQuery = gql`
  mutation CreatePostMutation(
    $young: ID!
    $category: String!
    $text: String
    $file: Upload
  ) {
    createPost(young: $young, category: $category, text: $text, file: $file) {
      id
      text
      createdAt
      category
      createdBy {
        name
        avatarUrl
        role
        avatar {
          url
        }
      }
    }
  }
`

const createYoungPostOptions = {
  props: ({ mutate }) => ({
    createPost: async ({ young, category, text, file }) => {
      return mutate({
        variables: {
          young,
          text,
          category,
          file,
        },

        refetchQueries: [
          {
            query: getYoungPostsQuery,
            variables: { young, category: '' },
          },
        ],
      })
    },
  }),
}

export default graphql(createYoungPostMutationQuery, createYoungPostOptions)
