import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { compose } from 'recompose'
import { Box } from 'design-system'
import ConversationItem from './conversation-item'
import { callSidebarPlaceholder } from 'utils/placeholder-factory'
import getYoungCase from 'graphql/queries/cases/getYoungCase'

const ConversationsYoung = ({ data, young }) => {
  const { owners, loading } = data

  if (loading) {
    return callSidebarPlaceholder()
  }

  return (
    <Box>
      {owners.length !== 0 &&
        owners.map(item => {
          const avatarSrc = item.avatar ? item.avatar.url : item.avatarUrl
          return (
            <StyledNavLink
              key={`team-${item.id}`}
              to={{
                pathname: `/user-chat-conversation/${item.id}`,
                aboutProps: { ...item },
              }}
            >
              <ConversationItem
                user={young}
                team={item.id}
                avatarSrc={avatarSrc}
                name={item.name}
              />
            </StyledNavLink>
          )
        })}
    </Box>
  )
}

export default compose(getYoungCase)(ConversationsYoung)

const StyledNavLink = styled(NavLink)({
  width: '100%',
  paddingLeft: 0,
  alignItems: 'center',
  display: 'flex',
})
