import { createMuiTheme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

export const MuiTheme = createMuiTheme({
  palette: {
    primary: {
      main: grey[900],
    },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily:
      '"Manrope", -apple-system, BlinkMacSystemFont, helvetica, ubuntu, roboto, sans-serif',
    fontSize: 16,
  },
})
