import React from 'react'
import { compose } from 'react-apollo'
import AuthRoute from 'auth-route'

import Menu from 'components/menu'
import Title from 'components/title'
import Conversations from 'components/conversations'
import { getCases } from 'graphql/queries/cases/getCases'
import { Box } from 'design-system/primitives'

const Contacts = compose(getCases)(Conversations)

const ChatList = ({ history, user }) => {
  return (
    <Box width="100vw" minHeight="100vh" maxWidth={5} mx="auto" bg="white">
      <Title title="Chat" mt={0} pt={4} mb={2} />
      <Contacts user={user} yourCases />
      <Menu value={1} />
    </Box>
  )
}
export default compose(AuthRoute)(ChatList)
