import React, { Fragment } from 'react'

import { Box } from 'design-system'

function Buttons({
  data,
  iconOne,
  iconTwo,
  onClickIconOne,
  onClickIconTwo,
  disabled,
  isUser,
}) {
  const disabledOpacity = 0.25

  function handleClickOne() {
    if (disabled) return
    onClickIconOne()
  }

  function handleClickTwo() {
    if (disabled) return
    onClickIconTwo()
  }

  return (
    <Fragment>
      {iconOne && (
        <Box
          display="flex"
          alignItems="center"
          onClick={handleClickOne}
          opacity={disabled ? disabledOpacity : 1}
          ml={isUser ? 'auto' : '0'}
        >
          {iconOne}
        </Box>
      )}
      {iconTwo && !isUser && (
        <Box
          display="flex"
          alignItems="center"
          onClick={handleClickTwo}
          ml={3}
          opacity={disabled ? disabledOpacity : 1}
        >
          {iconTwo}
        </Box>
      )}
    </Fragment>
  )
}

export default Buttons
