import React from 'react'
import styled from 'styled-components'

const BorderButton = ({ title, onClick, ...otherProps }) => (
  <Button {...otherProps} onClick={onClick}>
    {title}
  </Button>
)

const Button = styled.button(({ theme }) => ({
  fontWeight: theme.fontWeights[1],
  fontSize: theme.fontSizes[0],
  fontFamily: theme.fonts.title,
  color: '#0B0B0B',
  padding: '3px 5px',
  outline: 'none',
  backgroundColor: 'transparent',
  borderRadius: 3,
  height: '45px',
  width: '100%',
  margin: 5,
  transition: 'opacity 0.3s ease',
  cursor: 'pointer',
  borderColor: '#0B0B0B',
}))

export default BorderButton
