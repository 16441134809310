import React from 'react'
import { compose } from 'react-apollo'

import AuthRoute from 'auth-route'
import Menu from 'components/menu'
import Timeline from './timeline'
import { Box } from 'design-system'

const YoungTimelineIndex = ({ history, match, user }) => {
  return (
    <Box width="100vw" flex={1} minHeight="100vh" pb={3} mx="auto" maxWidth={5}>
      <Timeline history={history} match={match} user={user} />
      <Menu value={0} />
    </Box>
  )
}

export default compose(AuthRoute)(YoungTimelineIndex)
