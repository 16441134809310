import React from 'react'
import { Box } from 'design-system'

const SendButton = ({ onClick, color, children }) => (
  <Box
    height={40}
    width={40}
    borderRadius="50%"
    bg={color}
    display="flex"
    alignItems="center"
    justifyContent="center"
    alignSelf="flex-end"
    onClick={onClick}
  >
    {children}
  </Box>
)

export default SendButton
