import React, { useState } from 'react'
import { Box, theme, Text } from 'design-system'
import { compose } from 'recompose'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { LoginIcon, LoginButtonIcon } from '../../components/icons'
import loginMutation from '../../graphql/mutations/user/login'

import { Input, ButtonLogin } from './style'

const SignInSchema = Yup.object().shape({
  password: Yup.string().required('Password is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
})

const Login = ({ login, history }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleLoginTeam = async values => {
    const { email, password } = values
    setLoading(true)
    try {
      const res = await login({ email, password })
      const {
        data: {
          login: { token, user },
        },
      } = res
      setError(false)
      localStorage.setItem('token', token)
      localStorage.setItem('user', user.id)
      history.push('/people')
    } catch (e) {
      console.log('error ', error)
      setError(true)
    }
    setLoading(false)
  }

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={values => handleLoginTeam(values)}
      validationSchema={SignInSchema}
      autoCapitalize="off"
      autoComplete="off"
      autoCorrect="off"
      render={props => (
        <form onSubmit={props.handleSubmit}>
          <Box
            width="100vw"
            minHeight="100vh"
            display="flex"
            height="100vh"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            bg="white"
          >
            <Box my={2}>
              <LoginIcon fill={theme.colors.black} />
            </Box>
            <Text>Staff Login</Text>
            <Input
              type="email"
              name="email"
              placeholder="Email address"
              autoComplete="off"
              autoCapitalize="off"
              autoCorrect="off"
              value={props.values.email}
              onBlur={props.handleBlur}
              onChange={e => {
                props.handleChange(e)
                setError(false)
              }}
            />
            <Box
              height={10}
              width={300}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {props.errors.email && props.touched.email && (
                <Text
                  as="span"
                  textAlign="end"
                  fontSize={theme.fontSizes[0]}
                  fontFamily={theme.fonts.title}
                  color="red"
                >
                  {props.errors.email}
                </Text>
              )}
            </Box>
            <Input
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="off"
              value={props.values.password}
              onBlur={props.handleBlur}
              onChange={e => {
                props.handleChange(e)
                setError(false)
              }}
            />
            <Box
              height={10}
              width={300}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              {props.errors.password && props.touched.password && (
                <Text
                  as="span"
                  textAlign="end"
                  fontSize={theme.fontSizes[0]}
                  fontFamily={theme.fonts.title}
                  color="red"
                >
                  {props.errors.password}
                </Text>
              )}
              {error && (
                <Text
                  as="span"
                  textAlign="end"
                  fontSize={theme.fontSizes[0]}
                  fontFamily={theme.fonts.title}
                  color="red"
                >
                  User not found
                </Text>
              )}
            </Box>

            <ButtonLogin type="submit">
              {loading ? (
                <div className="dot" />
              ) : (
                <Box
                  width="70px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  Log in
                  <LoginButtonIcon
                    stroke={theme.colors.white}
                    width={19}
                    height={19}
                  />
                </Box>
              )}
            </ButtonLogin>
            <Text fontSize={14} onClick={() => history.push('/')}>
              Go back
            </Text>
          </Box>
        </form>
      )}
    />
  )
}

export default compose(loginMutation)(Login)
