import styled from 'styled-components'

export const Input = styled.input(({ theme }) => ({
  appearance: 'none',
  outline: 'none',
  width: '300px',
  height: '55px',
  border: '1.2px solid #cecece',
  borderRadius: '3px',
  margin: '10px',
  marginBottom: '3px',
  padding: '0 20px',
  fontSize: theme.fontSizes[1],
  color: theme.colors['dark-gray'],
}))

export const ButtonLogin = styled.button(({ theme }) => ({
  backgroundColor: theme.colors.black,
  margin: 10,
  padding: '3px 5px',
  fontWeight: theme.fontWeights[1],
  width: 300,
  border: 0,
  borderRadius: 3,
  fontFamily: theme.fonts.sans,
  color: theme.colors.white,
  fontSize: theme.fontSizes[1],
  height: '55px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
}))
