import React from 'react'
import { compose } from 'react-apollo'
import AuthRoute from 'auth-route'
import Menu from 'components/menu'
import Title from 'components/title'
import Settings from './young-settings'
import { Box } from 'design-system'

const YoungSettingsIndex = ({ history, client, user }) => (
  <Box width="100vw" minHeight="100vh" maxWidth={5} mx="auto" bg="white">
    <Title title="Settings" mt={0} pt={4} />

    <Settings young={user} history={history} client={client} />
    <Menu isUser value={3} />
  </Box>
)

export default compose(AuthRoute)(YoungSettingsIndex)
