import React from 'react'
import { compose } from 'recompose'
import AuthRoute from 'auth-route'
import Menu from 'components/menu'
import Title from 'components/title'
import Conversations from 'components/conversations-young'
import { Box } from 'design-system'

const UserChatList = ({ user, history }) => {
  return (
    <Box width="100vw" minHeight="100vh" maxWidth={5} mx="auto" bg="white">
      <Title title="Chat" />
      <Conversations young={user} />
      <Menu isUser value={2} />
    </Box>
  )
}
export default compose(AuthRoute)(UserChatList)
