import React from 'react'
import { Box } from 'design-system'
import { compose } from 'recompose'
import AuthRoute from 'auth-route'

import SendMessageChat from 'widgets/send-message-chat'
import HeaderName from 'widgets/header-name'
import FeedChat from 'widgets/feed-chat'

import sendMessage from 'graphql/mutations/chat/sendMessage'
import getYoungMessages from 'graphql/queries/young/getYoungMessages'

const Messages = compose(getYoungMessages)(FeedChat)
const SendMessageInput = compose(sendMessage)(SendMessageChat)

const ChatConversation = ({ data, history, match }) => {
  const { authStatus } = data
  const { user } = authStatus
  const { id } = match.params

  return (
    <Box
      width="100%"
      flex={1}
      minHeight="100vh"
      p={0}
      mx="auto"
      bg="white"
      maxWidth={5}
    >
      <HeaderName
        young={id}
        onClick={() => history.push(`/profile/${id}`)}
        onClickBack={() => history.goBack()}
      />
      <Messages young={id} user={user} />
      <SendMessageInput recipient={id} sender={user} />
    </Box>
  )
}

export default compose(AuthRoute)(ChatConversation)
