import React, { useState, useEffect } from 'react'
import { compose } from 'react-apollo'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import Avatar from '@material-ui/core/Avatar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { Text, Box } from 'design-system'
import { headerPlaceholder } from 'utils/placeholder-factory'
import Buttons from './buttons'

import getYoung from 'graphql/queries/young/getYoung'

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: 10,
    width: 32,
    height: 32,
  },
  initialsAvatar: {
    fontSize: 12,
    marginRight: 10,
    width: 32,
    height: 32,
    color: '#fff',
    backgroundColor: grey[500],
  },
}))

const HeaderName = ({
  young,
  onClick,
  onClickBack,
  iconOne,
  iconTwo,
  hasPosts,
  postsLoading,
  onClickIconOne,
  onClickIconTwo,
  data,
  isUser,
}) => {
  const [disabled, setDisabled] = useState(true)
  const classes = useStyles()

  useEffect(
    () => {
      // if (hasPosts && !postsLoading) {
      //   setDisabled(false)
      // }
      // if (!hasPosts && !postsLoading) {
      //   setDisabled(true)
      // }
      setDisabled(postsLoading)
    },
    [postsLoading]
  )

  return data && !data.young ? (
    headerPlaceholder()
  ) : (
    <>
      {data && data.young && (
        <Box
          height={70}
          width="90%"
          display="flex"
          mx="auto"
          maxWidth={5}
          alignItems="center"
        >
          {!isUser && (
            <Box mr={3} display="flex" alignItems="center">
              <ArrowBackIcon onClick={onClickBack} />
            </Box>
          )}

          <Box
            width="85%"
            display="flex"
            alignItems="center"
            flexDirection="row"
            onClick={onClick}
          >
            {data.young.avatar || data.young.avatarUr ? (
              <Avatar
                alt={data.young.name}
                src={
                  data.young.avatar
                    ? data.young.avatar.url
                    : data.young.avatarUrl
                }
                className={classes.avatar}
              />
            ) : (
              <Avatar className={classes.initialsAvatar}>
                {data.young.name.charAt(0).toUpperCase()}
                {data.young.name
                  .split(' ')[1]
                  .charAt(0)
                  .toUpperCase()}
              </Avatar>
            )}
            <Text fontFamily="title">{data.young.name}</Text>
          </Box>
          <Buttons
            young={young}
            iconOne={iconOne}
            iconTwo={iconTwo}
            disabled={disabled}
            onClickIconOne={onClickIconOne}
            onClickIconTwo={onClickIconTwo}
            isUser={isUser}
          />
        </Box>
      )}
    </>
  )
}

export default compose(getYoung)(HeaderName)
