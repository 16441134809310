import React from 'react'
import { Box } from 'design-system'
import Title from 'components/title'
import Menu from 'components/menu'
import CasesList from 'components/cases-list'

const UserContactList = ({ history }) => (
  <Box minHeight="100vh" maxWidth={5} mx="auto" overflow="scroll" bg="white">
    <Title title="Carmarthenshire" />
    <CasesList yourCases data={data} isUser />
    <Menu isUser value={2} />
  </Box>
)

export default UserContactList

const data = {
  loading: false,
  cases: [
    {
      young: {
        id: 'cjykatp6t1ey80721z8mskauj',
        name: 'Aaron Dovey',
        avatarUrl: 'https://storage.googleapis.com/muse-app/aaron-dovey.jpg',
        status: 'IN_COLLEGE',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatpc41eyd07212ocsay5j',
        name: 'Susan Dovey',
        avatarUrl: 'https://storage.googleapis.com/muse-app/bet.jpeg',
        status: 'IN_COLLEGE',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatpi71eyi0721dojhypay',
        name: 'Sophie Carter',
        avatarUrl: 'https://storage.googleapis.com/muse-app/cari.jpeg',
        status: 'IN_SCHOOL',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatpmu1eyn07213ucvgpdq',
        name: 'Jessica Philips',
        avatarUrl: 'https://storage.googleapis.com/muse-app/jessica.jpg',
        status: 'UNEMPLOYED',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatpw81eyx07215yjwjxih',
        name: 'Jacob Smith',
        avatarUrl: 'https://storage.googleapis.com/muse-app/steven.jpeg',
        status: 'WORKING',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatq161ez207215klrobhl',
        name: 'Angel Smith',
        avatarUrl: 'https://storage.googleapis.com/muse-app/stephanie.jpeg',
        status: 'IN_UNIVERSITY',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatq5t1ez70721iire384v',
        name: 'Thomas Jenkins',
        avatarUrl: 'https://storage.googleapis.com/muse-app/ray.jpeg',
        status: 'IN_SCHOOL',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
  ],
  allCases: [
    {
      young: {
        id: 'cjykatp1j1ey30721zk0yyd8t',
        name: 'Shannon Ray',
        avatarUrl: 'https://storage.googleapis.com/muse-app/shannon.jpeg',
        status: 'WORKING',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatp6t1ey80721z8mskauj',
        name: 'Aaron Dovey',
        avatarUrl: 'https://storage.googleapis.com/muse-app/aaron-dovey.jpg',
        status: 'IN_COLLEGE',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatpc41eyd07212ocsay5j',
        name: 'Susan Dovey',
        avatarUrl: 'https://storage.googleapis.com/muse-app/bet.jpeg',
        status: 'IN_COLLEGE',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatpi71eyi0721dojhypay',
        name: 'Sophie Carter',
        avatarUrl: 'https://storage.googleapis.com/muse-app/cari.jpeg',
        status: 'IN_SCHOOL',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatpmu1eyn07213ucvgpdq',
        name: 'Jessica Philips',
        avatarUrl: 'https://storage.googleapis.com/muse-app/jessica.jpg',
        status: 'UNEMPLOYED',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatpw81eyx07215yjwjxih',
        name: 'Jacob Smith',
        avatarUrl: 'https://storage.googleapis.com/muse-app/steven.jpeg',
        status: 'WORKING',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatq161ez207215klrobhl',
        name: 'Angel Smith',
        avatarUrl: 'https://storage.googleapis.com/muse-app/stephanie.jpeg',
        status: 'IN_UNIVERSITY',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
    {
      young: {
        id: 'cjykatq5t1ez70721iire384v',
        name: 'Thomas Jenkins',
        avatarUrl: 'https://storage.googleapis.com/muse-app/ray.jpeg',
        status: 'IN_SCHOOL',
        __typename: 'YoungUser',
      },
      __typename: 'Case',
    },
  ],
}
