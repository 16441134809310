import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import PersonIcon from '@material-ui/icons/Person'
import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import SettingsIcon from '@material-ui/icons/Settings'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { theme } from 'design-system'

const useStyles = makeStyles(t => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: '50%',
    width: '100vw',
    maxWidth: theme.maxWidths[5],
    transform: 'translateX(-50%)',
    zIndex: 999,
  },
}))

const Menu = ({ isUser, value }) => {
  const classes = useStyles()

  return (
    <Fragment>
      {isUser && (
        <BottomNavigation value={value} showLabels className={classes.root}>
          <BottomNavigationAction
            component={Link}
            icon={<DashboardIcon />}
            to="/user-timeline"
          />
          <BottomNavigationAction
            component={Link}
            icon={<PersonIcon />}
            to="/user-profile"
          />
          <BottomNavigationAction
            component={Link}
            icon={<ChatBubbleIcon />}
            to="/user-chat-list"
          />
          <BottomNavigationAction
            component={Link}
            icon={<SettingsIcon />}
            to="/user-settings"
          />
        </BottomNavigation>
      )}
      {!isUser && (
        <BottomNavigation value={value} showLabels className={classes.root}>
          <BottomNavigationAction
            component={Link}
            icon={<PersonIcon />}
            to="/people"
          />
          <BottomNavigationAction
            component={Link}
            icon={<ChatBubbleIcon />}
            to="/chat"
          />
          <BottomNavigationAction
            component={Link}
            icon={<SettingsIcon />}
            to="/settings"
          />
        </BottomNavigation>
      )}
    </Fragment>
  )
}

export default Menu
