import React from 'react'
import styled from 'styled-components'

const TextArea = ({ onChange, placeholder }) => (
  <TxtArea
    rows="4"
    resize="none"
    name="text"
    placeholder={placeholder}
    onChange={e => onChange(e)}
  />
)

const TxtArea = styled.textarea(({ theme }) => ({
  fontFamily: 'Manrope',
  borderRadius: theme.radii[2],
  fontSize: theme.fontSizes[3],
  outline: 'none',
  resize: 'none',
  border: 0,
  width: '100%',
  height: '100%',
  minHeight: theme.heights[3],
  marginBottom: theme.space[4],
  backgroundColor: '#FFFFFF',
  marginTop: 20,
  '::placeholder': {
    color: '#DDDDDD',
    opacity: 1,
    fontFamily: 'inherit',
    fontSize: theme.fontSizes[3],
  },
}))

export default TextArea
