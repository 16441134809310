import React from 'react'
import { Heading, Box } from 'design-system'

const ProfileCard = ({ title, children }) => (
  <Box width="90%" maxWidth={5} mx="auto" my={20}>
    <Box display="flex">
      <Heading
        color="off-white"
        fontWeight="normal"
        lineHeight="title"
        fontSize={1}
        flex={1}
        pl={2}
      >
        {title}
      </Heading>
    </Box>
    <Box
      bg="white"
      borderRadius={'5px'}
      display="flex"
      flexDirection="column"
      mt={2}
      p={3}
    >
      {children}
    </Box>
  </Box>
)

export default ProfileCard
