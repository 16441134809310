import React from 'react'

import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const Filter = ({ onFilter, options, filterItems }) =>
  options.map(item => (
    <FormControlLabel
      key={`filter-option-${item.id}`}
      value={item.title}
      control={<Radio />}
      label={item.title}
    />
  ))

export default Filter
