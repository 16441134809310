import React from 'react'
import { compose } from 'recompose'
import ListItemText from '@material-ui/core/ListItemText'

import getLastMessage from 'graphql/queries/chat/getLastMessage'

const ConversationItemText = ({ young, user, name, className, data }) => {
  const { message } = data

  return (
    <ListItemText
      className={className}
      primary={name}
      secondary={message ? message : 'Send a message'}
    />
  )
}

export default compose(getLastMessage)(ConversationItemText)
