import React, { useState } from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import NativeSelect from '@material-ui/core/NativeSelect'
import LinearProgress from '@material-ui/core/LinearProgress'

import { Box, Text, theme } from 'design-system'
import SideButtons from 'widgets/side-buttons'
import TextArea from 'components/textarea'
import { postCategories } from 'enums'

import createYoungPost from 'graphql/mutations/young/createYoungPost'

const useStyles = makeStyles(t => ({
  label: {
    marginRight: t.spacing(1),
    fontFamily: theme.fonts.sans,
  },
  select: {
    flex: 1,
    fontFamily: theme.fonts.sans,
  },
  progress: {
    height: 2,
    flexGrow: 1,
  },
}))

const CreateLog = ({ young, createPost, onClickCancel, onClickCreate }) => {
  const classes = useStyles()
  const [category, setCategory] = useState('EDUCATION')
  const [text, setText] = useState('')
  const [loading, setLoading] = useState(false)
  const file = null

  function handleChange(event) {
    setCategory(event.target.value)
  }

  function handleTextChange(event) {
    setText(event.target.value)
  }

  async function handleClickCreate() {
    if (loading) return false
    setLoading(true)
    await createPost({ young, category, text, file })
    setLoading(false)
    onClickCreate()
  }

  return (
    <Box
      position="relative"
      height="100vh"
      bg="white"
      flexDirection="column"
      width="100%"
      display="flex"
      alignItems="center"
      mx="auto"
      maxWidth={5}
      zIndex={10}
      justifyContent="space-between"
    >
      {loading && (
        <ProgressBox>
          <div className={classes.progress}>
            <LinearProgress thickness={2} />
          </div>
        </ProgressBox>
      )}

      <Box
        flex={1}
        width="100%"
        display="flex"
        alignItems="flex-start"
        mx="auto"
        p={3}
        maxWidth={5}
        flexDirection="column"
      >
        <Text
          fontFamily="title"
          color="black"
          fontSize={4}
          lineHeight="33px"
          m={0}
        >
          Create Log
        </Text>
        <TextArea
          onChange={handleTextChange}
          placeholder="Start Typing..."
          disabled={loading}
        />
        <Box
          width="100%"
          mb={2}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
        >
          <InputLabel htmlFor="category-select" className={classes.label}>
            Category
          </InputLabel>
          <NativeSelect
            inputProps={{
              name: 'category',
              id: 'category-select',
            }}
            value={category}
            onChange={handleChange}
            className={classes.select}
            disabled={loading}
          >
            {Object.keys(postCategories).map((k, index) => (
              <option key={index} value={k} defaultValue={k === category}>
                {postCategories[k]}
              </option>
            ))}
          </NativeSelect>
        </Box>
      </Box>
      <SideButtons
        titleBordered="Cancel"
        titleBlack="Create Log"
        onClickBlack={handleClickCreate}
        onClickBordered={onClickCancel}
      />
    </Box>
  )
}

export default compose(createYoungPost)(CreateLog)

const ProgressBox = styled(Box)({
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  'div.MuiLinearProgress-root': {
    height: 3,
  },
})
