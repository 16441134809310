import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export const getTeamMemberQuery = gql`
  query GetTeamMemberQuery($id: ID!) {
    getTeamMember(id: $id) {
      name
      avatarUrl
      avatar {
        url
      }
    }
  }
`

export const getTeamMemberOptions = {
  options: ({ id }) => ({
    variables: { id },
    fetchPolicy: 'cache-and-network',
  }),
}

export default graphql(getTeamMemberQuery, getTeamMemberOptions)
