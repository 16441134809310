import React from 'react'
import { compose } from 'react-apollo'
import AuthRoute from 'auth-route'

import Menu from 'components/menu'
import Timeline from 'pages/young/timeline/timeline'
import { Box } from 'design-system'

const UserTimeline = ({ history, match, user }) => {
  return (
    <Box>
      <Timeline history={history} match={match} user={user} isUser />
      <Menu value={0} isUser />
    </Box>
  )
}
export default compose(AuthRoute)(UserTimeline)
