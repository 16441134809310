import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

export const getCasesQuery = gql`
  query CasesQuery {
    cases {
      young {
        id
        name
        avatarUrl
        avatar {
          url
        }
        status
      }
    }
  }
`

export const getOtherCasesQuery = gql`
  query OtherCasesQuery {
    otherCases {
      young {
        id
        name
        avatarUrl
        avatar {
          url
        }
        status
      }
    }
  }
`

export const getOtherCasesOptions = {
  props: ({ data: { error, loading, otherCases } }) => ({
    data: {
      error,
      loading,
      otherCases,
      allCases: otherCases && otherCases.length > 0 ? otherCases : [],
    },
  }),
  options: {
    fetchPolicy: 'cache-and-network',
  },
}

export const getOtherCases = graphql(getOtherCasesQuery, getOtherCasesOptions)

export const getCasesOptions = {
  props: ({ data: { error, loading, cases } }) => ({
    data: {
      error,
      loading,
      cases,
      allCases: cases && cases.length > 0 ? cases : [],
    },
  }),
  options: {
    fetchPolicy: 'cache-and-network',
  },
}

export const getCases = graphql(getCasesQuery, getCasesOptions)
