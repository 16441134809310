import React from 'react'
import { compose } from 'react-apollo'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import Avatar from '@material-ui/core/Avatar'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { Text, Box } from 'design-system'
import { headerPlaceholder } from 'utils/placeholder-factory'

import getTeamMember from 'graphql/queries/user/getTeamMember'

const useStyles = makeStyles(theme => ({
  avatar: {
    marginRight: 10,
    width: 32,
    height: 32,
  },
  initialsAvatar: {
    fontSize: 12,
    marginRight: 10,
    width: 32,
    height: 32,
    color: '#fff',
    backgroundColor: grey[500],
  },
}))

const HeaderTeamMember = ({ id, onClickBack, postsLoading, data }) => {
  const classes = useStyles()

  return data && !data.getTeamMember ? (
    headerPlaceholder()
  ) : (
    <>
      {data && data.getTeamMember && (
        <Box
          height={70}
          width="90%"
          display="flex"
          mx="auto"
          maxWidth={5}
          alignItems="center"
        >
          <Box mr={3} display="flex" alignItems="center">
            <ArrowBackIcon onClick={onClickBack} />
          </Box>

          <Box
            width="85%"
            display="flex"
            alignItems="center"
            flexDirection="row"
          >
            {data.getTeamMember.avatarUrl ? (
              <Avatar
                alt={data.getTeamMember.name}
                src={data.getTeamMember.avatarUrl}
                className={classes.avatar}
              />
            ) : (
              <Avatar className={classes.initialsAvatar}>
                {data.getTeamMember.name.charAt(0).toUpperCase()}
                {data.getTeamMember.name
                  .split(' ')[1]
                  .charAt(0)
                  .toUpperCase()}
              </Avatar>
            )}
            <Text fontFamily="title">{data.getTeamMember.name}</Text>
          </Box>
        </Box>
      )}
    </>
  )
}

export default compose(getTeamMember)(HeaderTeamMember)
